<template>
  <div class="section">
    <header-page>
      <span class="size14 fw-bold-700" style="color: #0b5fff; margin-right: 5px"
        >Master</span
      >
      <span class="text-dark text-darken-5" style="margin-right: 5px">></span>
      <span class="text-dark fw-bold-400 size14">Maintenance</span>
    </header-page>

    <Table
      :result="result"
      :delete-data="deleteItem"
      :get-data="getMaintenanceData"
      :edit-item="editItem"
      @filterBank="getFilter"
    />

    <Modal
      :form-data="formPayload"
      :create-item="createItem"
      :edit-id="editId"
      :edit-item="editItem"
      :update-item="updateItem"
      :clean-up-form="cleanUpForm"
      @getPayload="getPayload"
    />
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from "lodash";
import { mapActions, mapState } from "vuex";
import { VBToggle } from "bootstrap-vue";
import {
  checkPermission,
  successNotification,
  errorNotification,
} from "@/auth/utils";

import HeaderPage from "@/components/HeaderPage.vue";
import Modal from "@/components/Master/Maintenance/Modal.vue";
import Table from "@/components/Master/Maintenance/Table.vue";

export default {
  title() {
    return "Master Bank";
  },
  components: {
    HeaderPage,
    Table,
    Modal,
  },
  computed: {
    ...mapState({
      maintenance: (state) => state.maintenance.maintenance,
    }),
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      currentPage: 1,
      isLoading: true,
      result: {},
      validations: [],
      editId: null,
      formPayload: {
        start_datetime: "",
        end_datetime: "",
        description: "",
        is_active: true,
      },
      filter: {
        search: "",
        per_page: 10,
        sort_type: "desc",
      },
    };
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    };
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getMaintenanceData();
      }, 300),
      deep: true,
    },
  },
  created() {
    this.getMaintenanceData();
  },
  methods: {
    ...mapActions("maintenance", [
      "getData",
      "postData",
      "deleteData",
      "showData",
    ]),
    getPayload(value) {
      this.formPayload = value;
    },
    getFilter(value) {
      this.filter = value;
    },
    cleanUpForm() {
      this.editId = null;
      this.formPayload = {
        start_datetime: "",
        end_datetime: "",
        description: "",
        is_active: 1,
      };
      this.validations = "";
    },
    editItem(item) {
      this.cleanUpForm();
      this.editId = item.uuid;
      this.$store.commit(
        "maintenance/SET_FORM_PAYLOAD",
        (this.formPayload = {
          start_datetime: item.start_datetime,
          end_datetime: item.end_datetime,
          description: item.description,
          is_active: item.is_active,
        })
      );
      this.$bvModal.show("modal-maintenance");
    },
    async getMaintenanceData(page = 1) {
      this.isLoading = true;
      const queryParams = this.filter;
      queryParams.page = page;
      await this.getData({
        params: queryParams,
      });
      this.result = this.maintenance;
      this.currentPage = this.maintenance.current_page;
      this.isLoading = false;
    },
    async createItem() {
      const form = this.preparePayload();
      this.isLoading = true;
      const response = await this.postData({
        id: "",
        payload: form,
      });
      if (response) {
        this.isLoading = false;
        this.$bvModal.hide("modal-maintenance");
        this.getMaintenanceData();
        successNotification(
          this,
          "Success",
          "Jadwal Maintenance berhasil di buat"
        );
        this.cleanUpForm();
      }
      // this.$store.dispatch('bank/postData', {
      //   query: '',
      //   payload: form,
      // })
      //   .then(() => {
      //     this.getMaintenanceData()
      //     successNotification(this, 'Success', 'Bank berhasil di buat')
      //     this.cleanUpForm()
      //     this.$bvModal.hide('modal-maintenance')
      //     this.isLoading = false
      //   })
      //   .catch(error => {
      //     if (error.response.data.meta.validations) {
      //       this.validations = error.response.data.meta.validations
      //     }
      //     this.isLoading = false
      //   })
    },
    async updateItem() {
      const form = this.preparePayload();
      this.isLoading = true;
      form.append("_method", "PATCH");

      const response = await this.postData({
        id: this.editId,
        payload: form,
      });
      if (response) {
        this.getMaintenanceData();
        successNotification(
          this,
          "Success",
          "Jadwal Maintenance berhasil diubah!"
        );
        this.cleanUpForm();
        this.isLoading = false;
        this.$bvModal.hide("modal-maintenance");
      }
      // this.$store.dispatch('bank/postData', {
      //   query: `/${this.editId}`,
      //   payload: form,
      // })
      //   .then(() => {
      //     this.$bvModal.hide('form-bank-modal')
      //     this.getMaintenanceData()
      //     successNotification(this, 'Success', 'Bank berhasil di ubah!')
      //     this.cleanUpForm()
      //     this.isLoading = false
      //     this.$bvModal.hide('modal-maintenance')
      //   })
      //   .catch(error => {
      //     if (error.response.data.meta.validations) {
      //       this.validations = error.response.data.meta.validations
      //       errorNotification(this, 'Oops!', error.response.data.meta.messages)
      //     }
      //     this.isLoading = false
      //   })
    },
    async deleteItem(uuid) {
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure to delete this Maintenance Schedule?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          const response = await this.deleteData({
            id: uuid,
          });
          this.getMaintenanceData();
          this.$swal({
            icon: "success",
            title: "Success!",
            text: "Jadwal Maintenance berhasil dihapus",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },
    preparePayload() {
      const form = new FormData();
      for (const key in this.formPayload) {
        if (
          this.formPayload.hasOwnProperty(key) &&
          this.formPayload[key] != null
        ) {
          if (key === "is_active") {
            form.append(key, this.formPayload[key] ? 1 : 0);
          } else {
            form.append(key, this.formPayload[key]);
          }
        }
      }
      return form;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";

.px-33 {
  padding-left: 33px;
  padding-right: 33px;
}
.px-16 {
  padding-left: 16px;
  padding-right: 16px;
}
.py-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.py-18 {
  padding-top: 18px;
  padding-bottom: 18px;
}

#app {
  background: #f6f7fa;
}

.master-toko {
  height: 100vh;
}
</style>
