<template>
  <div class="section section__bank">
    <!-- <div class="transaction__filter--container d-block bg-white py-2">
      <b-row class="search__export align-items-center">
        <b-col cols="10">
          <div class="d-flex pl-2">
            <b-img
              class="filter-image"
              :class="filter.sort_type == 'asc' ? 'rotate-180' : ''"
              :src="require('@/assets/images/icons/Icon-order-list-down.svg')"
              alt="Icon-order-list-down"
              role="button"
              @click="
                filter.sort_type == 'asc'
                  ? (filter.sort_type = 'desc')
                  : (filter.sort_type = 'asc')
              "
            />
            <div class="search__input w-100 pl-2 pr-0 mr-0">
              <b-input-group>
                <b-form-input v-model="search" placeholder="Cari nama Bank" />
                <b-input-group-append is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-append>
              </b-input-group>
            </div>
          </div>
        </b-col>
        <b-col cols="2" class="px-0">
          <div>
            <b-button
              class="py-1 border-8"
              @click="$bvModal.show('modal-maintenance')"
            >
              Tambah Jadwal
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div> -->
    <b-col cols="2" class="px-0">
      <div class="p-2">
        <b-button class="py-1 border-8" @click="$bvModal.show('modal-maintenance')">
          Tambah Jadwal Maintenance
        </b-button>
      </div>
    </b-col>
    <vue-good-table
      v-if="result.data"
      max-height="100vh"
      :columns="columns"
      :rows="result.data"
      :fixed-header="false"
      :sort-options="{
        enabled: false,
      }"
      :pagination-options="{
        enabled: false,
      }"
    >
      <template slot="table-column" slot-scope="props">
        <span v-if="props.column.field == 'action'" />
      </template>
      <template slot="table-row" slot-scope="props">
        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span>
            <div class="d-flex">
              <b-button
                class="mr-1 px-1 border-0 bg-pink"
                @click="deleteData(props.row.id)"
              >
                <feather-icon size="18" class="text-danger" icon="Trash2Icon" />
              </b-button>
              <b-button class="bg-white" @click="editItem(props.row)">
                <b-img :src="require('@/assets/images/icons/Process2.svg')" />
                Ubah
              </b-button>
            </div>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <!-- <div
      v-if="result.data"
      class="d-flex justify-content-between align-items-center flex-wrap bg-white border"
      style="padding: 0.8rem"
    >
      <div class="d-flex align-items-center">
        <b-form-select
          v-model="filter.per_page"
          :options="['10', '15', '20']"
          class="mx-1"
        />
        <span class="text-nowrap">
          Tampilkan 1 ke {{ filter.per_page }} dari
          {{ result.total }} transaksi.
        </span>
      </div>
      <div>
        <b-row class="mx-0 align-items-center">
          <b-col>
            <pagination
              class="mb-0"
              :data="result"
              :limit="4"
              :align="'right'"
              @pagination-change-page="getData"
            />
          </b-col>
        </b-row>
      </div>
    </div> -->
  </div>
</template>

<script>
/* eslint-disable vue/require-default-prop */
import {
  BRow,
  BCol,
  BFormSelect,
  VBToggle,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BButton,
} from "bootstrap-vue";

// import ModalBank from '@/components/Master/Bank/Modal/ModalBank.vue'

import { VueGoodTable } from "vue-good-table";

export default {
  components: {
    VueGoodTable,
    BFormSelect,
    // ModalBank,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    typeTable: {
      type: String,
    },
    result: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: "",
    },
    deleteData: {
      type: Function,
    },
    getData: {
      type: Function,
    },
    createItem: {
      type: Function,
    },
    editItem: {
      type: Function,
    },
    editId: {
      type: String,
    },
  },
  data() {
    return {
      progressValue: 35,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: "ID",
          field: "id",
        },
        {
          label: "Description",
          field: "description",
        },
        {
          label: "Start Date Time",
          field: "start_datetime",
        },
        {
          label: "End Date Time",
          field: "end_datetime",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      rows: [],
      items: [],
      search: "",
      filter: {
        search: "",
        sort_type: "desc",
        per_page: 10,
      },
    };
  },
  watch: {
    filter: {
      handler(value) {
        this.$emit("filterBank", value);
      },
      deep: true,
    },
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
.section__bank {
  .vgt-responsive {
    height: calc(100vh - 235px) !important;
    background-color: #fff;
  }
}
</style>
