<template>
  <b-modal
    id="modal-maintenance"
    hide-header
    hide-footer
    ok-only
    ok-title="Accept"
    centered
    size="lg"
    title="Large Modal"
    class="custom__modal--large"
  >
    <div class="section">
      <header
        class="sticky-top border border-light-bottom border-light-line border-light-top border-light-line p-2"
      >
        <b-row>
          <b-col cols="3" class="d-flex align-items-center">
            <div style="cursor: pointer" @click="directPage">
              <feather-icon
                size="24"
                class="text-dark font-weight-bolder"
                icon="ArrowLeftIcon"
              />
              <span class="text-dark text-darken-4 fw-bold-600 size14 ml-1"
                >Kembali</span
              >
            </div>
          </b-col>
          <b-col
            cols="6"
            class="d-flex justify-content-center align-items-center"
          >
            <h1 class="text-dark h6 font-weight-bolder mb-0 text-center">
              Informasi Jadwal Maintenance
            </h1>
          </b-col>
          <b-col cols="3" class="text-right">
            <b-button
              class="px-3"
              @click="editUuid == null ? createItem() : updateItem()"
            >
              Simpan
            </b-button>
          </b-col>
        </b-row>
      </header>
      <b-container class="mt-5 pt-5">
        <b-row class="justify-content-center h-100 bg-white pb-5">
          <b-col cols="12" md="5">
            <b-row>
              <!-- Deskripsi -->
              <b-col cols="12" md="12" class="mb-2 custom__form--input">
                <label for="v-deskripsi">Deskripsi</label>
                <b-form-group>
                  <b-form-textarea
                    id="v-deskripsi"
                    v-model="formPayload.description"
                    rows="5"
                    placeholder="Deskripsi Maintenance"
                    class="form-control"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="12" class="mb-2 custom__form--input">
                <label for="start_datetime">Waktu Mulai Maintenance</label>
                <b-form-group>
                  <flat-pickr
                    id="start_datetime"
                    v-model="formPayload.start_datetime"
                    class="form-control"
                    :config="{
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i:S',
                      time_24hr: true,
                      enableSeconds: true
                    }"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="12" class="mb-2 custom__form--input">
                <label for="end_datetime">Waktu Berakhir Maintenance</label>
                <b-form-group>
                  <flat-pickr
                    id="end_datetime"
                    v-model="formPayload.end_datetime"
                    class="form-control"
                    :config="{
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i:S',
                      time_24hr: true,
                      enableSeconds: true
                    }"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="12" class="mb-2 custom__form--input">
                <label for="end_datetime">Set Aktif</label>
                <div class="demo-inline-spacing" style="margin-top: 8px">
                  <div class="custom-control custom-radio mt-0">
                    <input
                      name="headlineOptions"
                      class="custom-control-input"
                      type="radio"
                      id="radio1"
                      value="true"
                      v-model="formPayload.is_active"
                    />
                    <label class="custom-control-label" for="radio1">
                      Yes
                    </label>
                  </div>
                  <div class="custom-control custom-radio mt-0">
                    <input
                      name="headlineOptions"
                      class="custom-control-input"
                      type="radio"
                      id="radio2"
                      value="false"
                      v-model="formPayload.is_active"
                    />
                    <label class="custom-control-label" for="radio2">
                      No
                    </label>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </b-modal>
</template>
<script>
/* eslint-disable vue/require-default-prop */
import {
  BModal,
  BContainer,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BAvatar,
  BFormDatepicker,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";

import UploadPhoto from "@/components/UploadPhoto.vue";

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BContainer,
    UploadPhoto,
    BAvatar,
    BFormDatepicker,
    flatPickr,
  },
  props: {
    formData: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: "",
    },
    createItem: {
      type: Function,
    },
    editItem: {
      type: Function,
    },
    editId: {
      type: String,
    },
    updateItem: {
      type: Function,
    },
    cleanUpForm: {
      type: Function,
    },
    iconBank: {
      type: String,
    },
  },
  data() {
    return {
      formPayload: {
        start_datetime: "",
        end_datetime: "",
        description: "",
        is_active: true,
      },
      editUuid: null,
    };
  },
  watch: {
    editId(value) {
      this.editUuid = value;
    },
    formData(value) {
      this.formPayload = value;
    },
    formPayload: {
      handler(value) {
        this.$emit("getPayload", value);
      },
      deep: true,
    },
  },
  methods: {
    getPhoto(value) {
      this.formPayload.logo = value.photo;
    },
    directPage() {
      this.cleanUpForm();
      this.$bvModal.hide("modal-maintenance");
    },
  },
};
</script>

<style lang="scss" scoped>
.box__upload--photo {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #eff1f5;
  border: 1px dashed #7a7f94;
  border-radius: 8px;
  position: relative;

  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;

  width: 195px;
  height: 140px;

  .floating__close--button {
    position: absolute;
    top: 6px;
    width: 24px;
    height: 24px;
    right: 6px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
